import '../styles/globals.css'

import Footer from './compents/Footer/Footer.jsx'
import Navbar from './compents/navbar/Navbar.jsx'

import '../styles/customSass.scss'




function MyApp({ Component, pageProps }) {
  return (
    <div className='d-flex flex-wrap vw-100 vh-100 justify-content-center'>
    <Navbar className="flex-shrink-1"/>
    <Component {...pageProps} className="" />
    <Footer/>
    
    </div>)

}

export default MyApp
