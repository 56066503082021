import React from 'react'


const Navbar = () => {
  return (
    
      <nav className='d-flex justify-content-center vw-100 bg-primary ' style={{height:"10%"}}>
       <a href='/' className='text-secondary text-decoration-none mh-100'><h1 className='p-1'>Meles s.r.o</h1></a>
      </nav>
           
   
  )
}

export default Navbar