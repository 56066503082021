import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { faBars, faCaretLeft } from '@fortawesome/free-solid-svg-icons'
export default function Footer (){
  

    

    const router = useRouter();
    const [hidden,setHidden] = useState(true);
    const [element,setElement] = useState();
    const [wrapper,setWrapper] = useState();
    useEffect(() => {
      setElement(document.getElementById('myNav'));
      setWrapper(document.getElementById("navWrapper"));
    },[])
    
    
    
  const handleBack = (e)=>{
    e.preventdefault;
    console.log(router.asPath)
    if(router.asPath == '/'){
      router.push("/thatsjustrude")
    }
    router.back();
  
    
  }
  function close() {
    
    element.classList.replace("d-flex", "d-none")
    setHidden(true)
  }
  
  function open() {
   
    element.classList.replace("d-none", "d-flex")
    setHidden(false)
  }
  const handleMenu =(e)=>{
    e.preventdefault;
    if(hidden){
      open()
    }
    else{
      close()
    }
    
  }
  return (
   
    <div className=" align-self-end bg-primary justify-content-between fixed-bottom m-0 mt-5 " id='navWrapper'>
      <div className='w-100 row'>
      <FontAwesomeIcon onClick={(e)=>handleBack(e)} icon={faCaretLeft} className="col text-secondary" style={{height:"2rem"}}/>
      <p className="col-6"> </p>
      <FontAwesomeIcon onClick={e=>handleMenu(e)} icon={faBars} className="col text-secondary" style={{height:"2rem"}}/>
      </div>
     
      <nav className='d-none flex-wrap flex-row align-content-center justify-content-center vh-50  p-0 h-50 bg-light text-primary' id='myNav'>
        <h2 className={ router.asPath === "/" ?"w-100 text-center bg-secondary-extreme": "w-100 text-center" } text-center onClick={()=>{router.push('/');close()}}>Domov</h2>
        <h2 className={router.asPath === "/buy" ?"w-100 text-center bg-secondary-extreme": "w-100 text-center"} onClick={()=>{router.push('/buy');close()}}>Predaj paliet</h2>
        <h2 className={router.asPath === "/sell" ?"w-100 text-center bg-secondary-extreme": "w-100 text-center"}onClick={()=>{router.push("/sell");close()}}>Výkup paliet</h2>
        
        <h2 className={router.asPath === "/product" ?"w-100 text-center bg-secondary-extreme": "w-100 text-center"} onClick={()=>{router.push("/product");close()}}>Naša Ponuka</h2>
        <h2 className={router.asPath === "/promo" ?"w-100 text-center bg-secondary-extreme": "w-100 text-center"}onClick={()=>{router.push("/promo");close()}}>Spolupráca</h2>
        <h2 className={router.asPath === "/contact" ?"w-100 text-center bg-secondary-extreme": "w-100 text-center"} onClick={()=>{router.push("/contact");close()}}>Kontakt</h2>
        <h2 className={router.asPath === "/admin" ?"w-100 text-center bg-secondary-extreme": "w-100 text-center"} onClick={()=>{router.push("/admin");close()}}>Admin</h2>
      </nav>
    </div>
    
   
  )
  
  
  
  
}


